
<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Human Resources Application
    Author: Hossam Ali
    Author URL: https://www.linkedin.com/in/hossam-ali-7bb41810b/
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full full-page-bg-color vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 xl:w-1/3 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col sm:w-full md:w-full d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="mb-5">
                  <img
                    src="@/assets/images/pages/titd_new_logo.png"
                    alt="login"
                    class="mx-auto w-48"
                  />
                </div>
                <div class="vx-card__title mb-4 text-center">
                  <p class="mb-10">Welcome back, please login to your account.</p>
                </div>

                <validation-observer ref="observer" v-slot="{ invalid }">
                  <form>
                    <ValidationProvider name="username" rules="required|min:2" v-slot="{ errors }">
                      <vs-input
                        :danger="!!errors[0]"
                        :danger-text="errors[0]"
                        name="username"
                        icon-no-border
                        icon="icon icon-user"
                        icon-pack="feather"
                        label-placeholder="Username"
                        v-model="username"
                        class="w-full"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      name="company_password"
                      rules="required|min:8"
                      v-slot="{ errors }"
                    >
                      <vs-input
                        :danger="!!errors[0]"
                        :danger-text="errors[0]"
                        type="password"
                        name="password"
                        icon-no-border
                        icon="icon icon-lock"
                        icon-pack="feather"
                        label-placeholder="Password"
                        v-model="password"
                        class="w-full mt-6"
                      />
                    </ValidationProvider>

                    <div class="mt-5 text-center">
                      <vs-button @click="loginJWT" :disabled="invalid" class="my-4">Login</vs-button>
                      <div class="mt-1">
                        <router-link to>Forgot Password?</router-link>
                      </div>
                    </div>
                  </form>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { required, min, numeric } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});
extend("numeric", {
  ...numeric,
  message: "field should be a number",
});

export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return true;
      }
      return false;
    },
    loginJWT() {
      if (this.checkLogin()) return;

      // Loading
      this.$vs.loading();

      const payload = {
        userDetails: {
          username: this.username,
          password: this.password,
        },
      };

      this.$store
        .dispatch("auth/loginJWT", payload)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },
};
</script>
<style lang="scss">
.bg-gray {
  background-color: #ededed;
}
</style>
